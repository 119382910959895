import {post} from '~/api/index';
import handleException from '~/services/exceptions';
import {AIWriteContentType} from '~/@types/ai';

export default class AiApi {
  public static resourceName = '/ai';

  /**
   * Chat with the AI
   */
  static async chat (message: string): Promise<string | null> {
    try {
      const response = await post(`${this.resourceName}/chat`, {message});

      if (response.code === 200 && response.data) {
        return response.data;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      return null;
    }
  }

  /**
   * Chat with the AI
   */
  static async writeContent (contentType: AIWriteContentType, message: string): Promise<string | null> {
    try {
      const response = await post(`${this.resourceName}/write-content`, {contentType, message});

      if (response.code === 200 && response.data) {
        return response.data;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      return null;
    }
  }

  /**
   * Chat with the AI
   */
  static async checkToPreformAction (actionReqMessage: string, userInput: string, command: string): Promise<string | null> {
    try {
      const response = await post(`${this.resourceName}/check-to-preform-action`, {actionReqMessage, userInput, command});

      if (response.code === 200 && response.data) {
        return response.data;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      return null;
    }
  }
}
